/*------------------------------------*\
    MAIN
\*------------------------------------*/

/* global box-sizing */
*,
*:after,
*:before {
    -moz-box-sizing:border-box;
    box-sizing:border-box;
    -webkit-font-smoothing:antialiased;
    font-smoothing:antialiased;
    text-rendering:optimizeLegibility;
}
/* html element 62.5% font-size for REM use */
html {
    font-size:62.5%;
}
body {
    font:300 10px/1.4 'Helvetica Neue', Helvetica, Arial, sans-serif;
    color:#444;
}
/* clear */
.clear:before,
.clear:after {
    content:' ';
    display:table;
}

.clear:after {
    clear:both;
}
.clear {
    *zoom:1;
}
img {
    max-width:100%;
    vertical-align:bottom;
}
a {
    color:#444;
    text-decoration:none;
}
a:hover {
    color:#444;
}
a:focus {
    outline:0;
}
a:hover,
a:active {
    outline:0;
}
input:focus {
    outline:0;
    border:1px solid #04A4CC;
}

/*------------------------------------*\
    STRUCTURE
\*------------------------------------*/

/* wrapper */
.wrapper {
    width: 100%;
    height: 100vh;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
}

/* header */
.header {

}

/* logo */
.logo {

}

.logo-img {

}

/* nav */
.nav {

}

/* footer */
.footer {

}

/*------------------------------------*\
    CANVAS
\*------------------------------------*/

.bg-canvas {
    position: relative;
    width: 100%;
    height: 100vh;
    background: #ccc;
    padding: 0;
    margin: 0;
}

/*------------------------------------*\
    CONTENT
\*------------------------------------*/

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  padding: 40px;

  h1 {
    font-size: 2.4em;
    line-height: 1;
    text-transform: uppercase;
    margin: 0;
  }

  h3 {
    margin: 0;
  }
}

/*------------------------------------*\
    KEYFRAMES
\*------------------------------------*/

@keyframes keyframe {
  0% {

  }
  50% {

  }
  100% {
;
  }
}

/*------------------------------------*\
    RESPONSIVE
\*------------------------------------*/

@media only screen and (min-width:320px) {

}
@media only screen and (min-width:480px) {

}
@media only screen and (min-width:768px) {

}
@media only screen and (min-width:1024px) {

}
@media only screen and (min-width:1140px) {

}
@media only screen and (min-width:1280px) {

}
@media only screen and (-webkit-min-device-pixel-ratio:1.5),
       only screen and (min-resolution:144dpi) {

}

/*------------------------------------*\
    MISC
\*------------------------------------*/

::selection {
    background:#04A4CC;
    color:#FFF;
    text-shadow:none;
}
::-webkit-selection {
    background:#04A4CC;
    color:#FFF;
    text-shadow:none;
}
::-moz-selection {
    background:#04A4CC;
    color:#FFF;
    text-shadow:none;
}
